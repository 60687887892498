<template>
  <div>
      <h1>CONNECT. COMPETE. CELEBRATE.</h1>
      <p>
        Sync your device, choose your challenges and mark your achievements with medals and merchandise.
      </p>
      <p>
        <v-btn color="primary" :href="tenant.profileFaqUrl">Explore Knowledge base</v-btn>
      </p>
      <p>
        <v-btn outlined color="primary" :href="`mailto:${tenant.contactEmail}`">Send an email</v-btn>
      </p>

  </div>
</template>
<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "About",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
</style>